var coal_name = ''
var coal_id = 0
var system_name = ''
var system_id = 0
var subSystem_name = ''
var subSystem_id = 0
var equipment_name = ''
var equipment_id = 0
var sub_equipment_name = ''
var sub_equipment_id = 0
var measure_point_name = ''
var measure_point_id = 0

const MODE_COAL = "MODE_COAL"
const MODE_SYSTEM = "MODE_SYSTEM"
const MODE_SUB_SYSTEM = "MODE_SUB_SYSTEM"
const MODE_EQUIPMENT = "MODE_EQUIPMENT"
const MODE_SUB_EQUIPMENT = "MODE_SUB_EQUIPMENT"
const MODE_MEASURE_POINT = "MODE_MEASURE_POINT"

const processList = [{
    subEquipId: 0,
    process: 0
}]

const processMap = new Map()

export default {
    coal_name,
    coal_id,
    system_name,
    system_id,
    subSystem_name,
    subSystem_id,
    equipment_name,
    equipment_id,
    sub_equipment_name,
    sub_equipment_id,
    measure_point_name,
    measure_point_id,

    MODE_COAL,
    MODE_SYSTEM,
    MODE_SUB_SYSTEM,
    MODE_EQUIPMENT,
    MODE_SUB_EQUIPMENT,
    MODE_MEASURE_POINT,

    processList,
    processMap
}
