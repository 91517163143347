import Vue from "vue";
import Router from "vue-router";
import http from "@/utils/httpRequest";
import { isURL } from "@/utils/validate";
import { clearLoginInfo } from "@/utils";
import store from '@/store/index';
Vue.use(Router);

// 开发环境不使用懒加载, 因为懒加载页面太多的话会造成webpack热更新太慢, 所以只有生产环境使用懒加载
const _import = require("./import-" + process.env.NODE_ENV);

// 全局路由(无需嵌套上左右整体布局)
const globalRoutes = [
  {
    path: "/404",
    component: _import("common/404"),
    name: "404",
    meta: { title: "404未找到" },
  },
  {
    path: "/login",
    component: _import("common/login"),
    name: "login",
    meta: { title: "登录" },
  },
  {
    path: "/Jump",
    component: _import("common/Jump"),
    name: "Jump",
    meta: { title: "跳转" },
  },
];

// 主入口路由(需嵌套上左右整体布局)
const mainRoutes = {
  path: "/",
  component: _import("main"),
  name: "main",
  redirect: { name: "homepage" },
  meta: { title: "主入口整体布局" },
  children: [
    // 通过meta对象设置路由展示方式
    // 1. isTab: 是否通过tab展示内容, true: 是, false: 否
    // 2. iframeUrl: 是否通过iframe嵌套展示内容, '以http[s]://开头': 是, '': 否
    // 提示: 如需要通过iframe嵌套展示内容, 但不通过tab打开, 请自行创建组件使用iframe处理!
    {
      path: "/home",
      component: _import("modules/operationCenter/homepage"),
      name: "homepage",
      meta: { title: "首页" },
    },
    {
      path: "/theme",
      component: _import("common/theme"),
      name: "theme",
      meta: { title: "主题" },
    },
    {
      path: "/demo-echarts",
      component: _import("demo/echarts"),
      name: "demo-echarts",
      meta: { title: "demo-echarts", isTab: true },
    },
    //  { path: '/demo-ueditor', component: _import('demo/ueditor'), name: 'demo-ueditor', meta: { title: 'demo-ueditor', isTab: true } }
    { path:'/dataAccessManage', component: _import('modules/dataManage/dataAccessManage/dataAccessManage'), name: 'dataAccessManage', meta: { title: '返回数据接入任务首页', isTab: false } },
    { path: '/dataList-checkData', component: _import('modules/dataManage/dataList/checkData'), name: 'checkData', meta: { title: '查看系统信息', isTab: false } },
    { path: '/dataList-dataDetail', component: _import('modules/dataManage/dataList/dataDetail'), name: 'dataDetail', meta: { title: '查看设备信息', isTab: false } },
    { path: '/addDataAccessTask', component: _import('modules/dataManage/dataAccessManage/addDataAccessTask'), name: '添加数据接入任务', meta: { title: '添加数据接入任务', isTab: false } },
    { path: '/dataQualitySubsystem', component: _import('modules/dataQuality/dataQualitySubsystem'), name: '查看子系统数据质量', meta: { title: '查看子系统数据质量', isTab: false } },
    { path: '/dataQualityDevice', component: _import('modules/dataQuality/dataQualityDevice'), name: '查看设备数据质量', meta: { title: '查看设备数据质量', isTab: false } },
    { path: '/dataQualityMeasurePoint', component: _import('modules/dataQuality/dataQualityMeasurePoint'), name: '查看数据项数据质量', meta: { title: '查看数据项数据质量', isTab: false } },
    { path: '/dataQualitySubdevice', component: _import('modules/dataQuality/dataQualitySubdevice'), name: '查看附属设备数据质量', meta: { title: '查看附属设备数据质量', isTab: false } },
    { path: '/checkDataquality', component: _import('modules/dataQuality/checkDataquality'), name: '查看数据质量信息', meta: { title: '查看数据质量信息', isTab: false } },
    { path: '/checkDevice', component: _import('modules/dataQuality/checkDevice'), name: '查看设备', meta: { title: '查看设备', isTab: false } },
      { path: '/metaData-term', component: _import('modules/metaData/term'), name: 'metaData-term', meta: { title: '查看术语表', isTab: false } },
      { path: '/metaData-tag', component: _import('modules/metaData/tag'), name: 'metaData-tag', meta: { title: '查看分类', isTab: false } },
      { path: '/dataExport-equipment', component: _import('modules/dataManage/dataExport/data-export-equipment'), name: 'data-export-equipment', meta: { title: '查看系统设备', isTab: false } },
       { path: '/dataExport-subEquipment', component: _import('modules/dataManage/dataExport/data-export-subEquipment'), name: 'data-export-subEquipment', meta: { title: '查看系统子设备', isTab: false } },
       { path: '/levelManage', component: _import('modules/levelManage/level'), name: 'levelManage', meta: { title: '层级关系管理', isTab: false } },
       { path: '/universalTable', component: _import('modules/levelManage/universal-table'), name: 'universal-table', meta: { title: '通用表格', isTab: false } },
       { path: '/universalTableMeta', component: _import('modules/dataManage/dataList/cengji/universal-table-meta'), name: 'universal-table-meta', meta: { title: '数据列表里通用表格和数据', isTab: false } },
       { path: '/universalTableDataQuality', component: _import('modules/dataQuality/universal-table-dataQuality'), name: 'universal-table-dataQuality', meta: { title: '数据质量里通用表格和数据', isTab: false } },
       { path: '/universalTableDataExport', component: _import('modules/dataManage/dataExport/universal-table-meta-dataExport'), name: 'universal-table-meta-dataExport', meta: { title: '数据列表里数据导出通用表格和数据', isTab: false } },
    { path: '/projectManage', component: _import('modules/levelManage/projectManage'), name: 'projectManage', meta: { title: '项目管理', isTab: false } },
  ],
  beforeEnter (to, from, next) {
    let token = Vue.cookie.get('token')
    if (!token || !/\S/.test(token)) {
      clearLoginInfo()
      // next({ name: 'login' })
      window.location.href = store.state.login_page
    }
    if (window.sessionStorage.getItem("user")) {
      next()
    } else {
      // next()
      window.location.href = store.state.login_page
    }
  }
}

const router = new Router({
  mode: "hash",
  scrollBehavior: () => ({ y: 0 }),
  isAddDynamicMenuRoutes: false, // 是否已经添加动态(菜单)路由
  routes: globalRoutes.concat(mainRoutes),
});

router.beforeEach((to, from, next) => {
  // 添加动态(菜单)路由
  // 1. 已经添加 or 全局路由, 直接访问
  // 2. 获取菜单列表, 添加并保存本地存储
  if (
      router.options.isAddDynamicMenuRoutes ||
      fnCurrentRouteType(to, globalRoutes) === "global"
  ) {
    next();
  } else {
    http({
      url: http.adornUrl("/sys/menu/nav"),
      method: "get",
      params: http.adornParams(),
    })
        .then(({ data }) => {
          if (data && data.code === 0) {
            //  console.log(data.menuList);
            fnAddDynamicMenuRoutes(data.menuList);
            router.options.isAddDynamicMenuRoutes = true;
            sessionStorage.setItem(
                "menuList",
                JSON.stringify(data.menuList || "[]")
            );
            sessionStorage.setItem(
                "permissions",
                JSON.stringify(data.permissions || "[]")
            );
            next({ ...to, replace: true });
          } else {
            sessionStorage.setItem("menuList", "[]");
            sessionStorage.setItem("permissions", "[]");
            next();
          }
        })
        .catch((e) => {
          console.log(
              `%c${e} 请求菜单列表和权限失败，跳转至登录页！！`,
              "color:blue"
          );
           window.location.href = store.state.login_page
          // router.push({ name: "login" });
        });
  }
});

/**
 * 判断当前路由类型, global: 全局路由, main: 主入口路由
 * @param {*} route 当前路由
 */
function fnCurrentRouteType(route, globalRoutes = []) {
  var temp = [];
  for (var i = 0; i < globalRoutes.length; i++) {
    if (route.path === globalRoutes[i].path) {
      return "global";
    } else if (
        globalRoutes[i].children &&
        globalRoutes[i].children.length >= 1
    ) {
      temp = temp.concat(globalRoutes[i].children);
    }
  }
  return temp.length >= 1 ? fnCurrentRouteType(route, temp) : "main";
}

/**
 * 添加动态(菜单)路由
 * @param {*} menuList 菜单列表
 * @param {*} routes 递归创建的动态(菜单)路由
 */
function fnAddDynamicMenuRoutes(menuList = [], routes = []) {
  var temp = [];
  for (var i = 0; i < menuList.length; i++) {
    if (menuList[i].list && menuList[i].list.length >= 1) {
      temp = temp.concat(menuList[i].list);
    } else if (menuList[i].url && /\S/.test(menuList[i].url)) {
      menuList[i].url = menuList[i].url.replace(/^\//, "");
      var route = {
        path: menuList[i].url.replace("/", "-"),
        component: null,
        name: menuList[i].url.replace("/", "-"),
        meta: {
          menuId: menuList[i].menuId,
          title: menuList[i].name,
          isDynamic: true,
          isTab: false,
          iframeUrl: "",
          tableId: menuList[i].tableId,
        },
      };
      // url以http[s]://开头, 通过iframe展示
      if (isURL(menuList[i].url)) {
        route["path"] = `i-${menuList[i].menuId}`;
        route["name"] = `i-${menuList[i].menuId}`;
        route["meta"]["iframeUrl"] = menuList[i].url;
      } else {
        try {
          route["component"] = _import(`modules/${menuList[i].url}`) || null;
        } catch (e) {}
      }
      routes.push(route);
    }
  }
  if (temp.length >= 1) {
    fnAddDynamicMenuRoutes(temp, routes);
  } else {
    mainRoutes.name = "main-dynamic";
    mainRoutes.children = routes;
    router.addRoutes([mainRoutes, { path: "*", redirect: { name: "404" } }]);
    sessionStorage.setItem(
        "dynamicMenuRoutes",
        JSON.stringify(mainRoutes.children || "[]")
    );
    console.log("\n");
    console.log(
        "%c!<-------------------- 动态(菜单)路由 s -------------------->",
        "color:blue"
    );
    console.log(mainRoutes.children);
    console.log(
        "%c!<-------------------- 动态(菜单)路由 e -------------------->",
        "color:blue"
    );
  }
}

export default router;
