import axios from 'axios' //前后端连接的
import Vue from 'vue'
import {
    Message
} from "element-ui"; //这个浏览器弹窗
import router from '../router/index'
import {clearLoginInfo} from "./index";
import store from '@/store/index';
axios.defaults.headers["Content-Type"] = "application/json; charset=utf-8";
axios.interceptors.request.use(config => {
    config.headers['token'] = Vue.cookie.get('token') // 请求头带上token
    return config
}, error => {
    return Promise.reject(error)
})
axios.interceptors.response.use(success=>{
    // if (success.status && success.status == 200 && success.data.status == 500) {
    //     Message.error({message:success.data.msg})
    //     return;
    // }
    if (success?.data?.code == 401) {
        window.location.href = store.state.login_page;
        // router.push({ name: 'login' })
    }
    if (success.data.msg) {
        if (success.data.msg === "success") {
            return success;
        }
        if (success.data.code === 500 || success.data.status === 500) {
            Message.error({message: success.data.msg})
        } else {
            Message.success({message: success.data.msg})
        }
    }
    // if (success.data.msg) {
    //     Message.success({message: success.data.msg})
    // }
    return success;
},error => {
    if (error.response.status == 504 || error.response.status == 404){
        Message.error({message:'服务器异常 '})
    }else if (error.response.status == 403){
        Message.error({message:'权限不足，请联系管理员'})
    }else if (error.response.status == 401){
        Message.error({message:'尚未登录，请登录'})
        router.replace('/');
    }else {
        if (error.response.data.msg){
            Message.error({message:error.response.data.msg})
        } else {
            Message.error({message:'未知错误'})
        }
    }
    return;
});

let base = '/bigdata'; //http://localhost:8081

export const postKeyValueRequest = (url, params) => {
    return axios({
        method: 'post',
        /*post请求*/
        url: `${base}${url}`,
        /*请求的地址*/
        data: params,
        /*请求参数，从浏览器页面获得的用户名和密码*/
        transformRequest: [function (data) {
            let ret = '';
            for (let i in data) {
                ret += encodeURIComponent(i) + '=' + encodeURIComponent(data[i]) + '&'
            }
            console.log(ret);
            return ret;
        }],
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
};

export const postRequest = (url, params) => {
    return axios({
        method: 'post',
        url: `${base}${url}`,
        data: params
    })
};
export const postFilesRequest = (url, params) => {
    return axios({
        method: 'post',
        url: `${base}${url}`,
        data: params,
        contentType:"multipart/form-data"
    })
};
export const putRequest = (url, params) => {
    return axios({
        method: 'put',
        url: `${base}${url}`,
        data: params,
        dataType: "JSON",
        contentType: 'application/json;charset=UTF-8',
    })
};
export const getRequest = (url, params) => {
    return axios({
        method: 'get',
        url: `${base}${url}`,
        params: params
    })
};
// var fun1 = async function(){
//     await axios({
//         method: 'get',
//         url: `${base}${url}`,
//         params: params
//     })
// }
// export const getRequest = fun1;
export const deleteRequest = (url, params) => {
    return axios({
        method: 'delete',
        url: `${base}${url}`,
        params: params
    })
};
