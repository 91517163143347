//将数组转化成树形结构 
export const treeDataTranslate = (list) => {
    const map = list.reduce((res,v)=>{
        res[v.id] = v;
        return res;
    },{})
    const res = [];
    for(let item of list){
        if(item.parentId === 0){
            res.push(item);
            continue;
        }
        if(item.parentId in map){
            const parent = map[item.parentId];
            parent.children = parent.children || [];
            parent.children.push(item);
        }
    }
    return res;
}
