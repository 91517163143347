import Vue from "vue";
import Vuex from "vuex";
import cloneDeep from "lodash/cloneDeep";
import common from "./modules/common";
import user from "./modules/user";
import file from "./modules/file";
import { Notification } from "element-ui";
import { getRequest } from "../utils/api";

Vue.use(Vuex);

const now = new Date();

const store = new Vuex.Store({
  modules: {
    common,
    user,
    file,
  },
  state: {
    // metaDataIp: "49.235.67.21",
    metaDataIp: window.config.metaDataIp,
    backendIp: window.config.backendIp,

    websocket: window.config.websocket,
    monitor: window.config.monitor,
    data_gear: window.config.data_gear,
    login_page: window.config.login_page,
    ai_plat: window.config.ai_plat,
    cloud_plat: window.config.cloud_plat,
    large_screen: window.config.large_screen,
    dq_iframe: window.config.dq_iframe
    // backendIp: "127.0.0.1",
    // routes: [],
    // sessions: {},
    // hrs: [],
    // currentSession: null,
    // currentHr: JSON.parse(window.sessionStorage.getItem("user")),
    // filterKey: '',
    // stomp: null,
    // isDot: {},
    // hbasetablename:'',
    // mpName:[]
  },
  getters: {
    // 文件查看模式
    fileModel: (state) =>
      state.file.fileModel === null ? 0 : Number(state.file.fileModel),
    // 网格模式 & 时间线模式下 文件图标大小
    gridSize: (state) => state.file.gridSize,
  },
  mutations: {
    // 重置vuex本地储存状态
    resetStore(state) {
      Object.keys(state).forEach((key) => {
        state[key] = cloneDeep(window.SITE_CONFIG["storeState"][key]);
      });
    },
    // setHbaseTablename(state, table){
    //     state.hbasetablename = table;
    // },
    // setmpName(state, mpname){
    //     state.mpName = mpname;
    // },
    // INIT_CURRENTHR(state, hr) {
    //     state.currentHr = hr;
    // },
    // initRoutes(state, data) {
    //     state.routes = data;
    // }
  },
  // strict: process.env.NODE_ENV !== 'production'
});

store.watch(
  function (state) {
    return state.sessions;
  },
  function (val) {
    localStorage.setItem("vue-chat-session", JSON.stringify(val));
  },
  {
    deep: true /*这个貌似是开启watch监测的判断,官方说明也比较模糊*/,
  }
);

export default store;
