import Vue from "vue";
import { Message } from "element-ui";
import {
  fileImgMap,
  unknownImg,
  fileSuffixCodeModeMap,
  markdownFileType,
} from "@/libs/map.js";
import { officeFileType } from "@/libs/map.js";

// 全局函数 - 文件相关
const fileFunction = {
  /**
   * 格式化文件大小
   * @param {number} size 文件大小
   * @param {boolean} isInteger 是否只显示整数位，默认不截取
   * @returns {string} 文件大小（带单位）
   */
  calculateFileSize(size, isInteger = false) {
    const B = 1024;
    const KB = Math.pow(1024, 2);
    const MB = Math.pow(1024, 3);
    const GB = Math.pow(1024, 4);
    if (isInteger) {
      // 截取为整数
      if (size < B) {
        return `${size}B`;
      } else if (size < KB) {
        return `${(size / B).toFixed(0)}KB`;
      } else if (size < MB) {
        return `${(size / KB).toFixed(0)}MB`;
      } else if (size < GB) {
        return `${(size / MB).toFixed(0)}GB`;
      } else {
        return `${(size / GB).toFixed(0)}TB`;
      }
    } else {
      // 保留小数位
      if (size < B) {
        return `${size}B`;
      } else if (size < KB) {
        return `${(size / B).toFixed(0)}KB`;
      } else if (size < MB) {
        return `${(size / KB).toFixed(1)}MB`;
      } else if (size < GB) {
        return `${(size / MB).toFixed(2)}GB`;
      } else {
        return `${(size / GB).toFixed(3)}TB`;
      }
    }
  },

  /**
   * 获取文件查看路径
   * @param {object} row 文件信息
   * @returns {string} 文件路径
   */
  getViewFilePath(row) {
    return `${config.baseContext}/filetransfer/preview?userFileId=${
      row.userFileId
    }&isMin=false&shareBatchNum=${
      row.shareBatchNum == null ? "" : row.shareBatchNum
    }&extractionCode=${row.extractionCode == null ? "" : row.extractionCode}`;
  },
  /**
	 * 文件预览
	 * @description 进行相应的预览。
	 * @param {object} row 文件信息

	 */
  handleFileNameClick(row, currentIndex, fileList = []) {
	let type = row.pathSuffix.split(".")[1];
    // 若当前点击项是图片
    const PIC = ["png", "jpg", "jpeg", "gif", "svg"];
    if (PIC.includes(type.toLowerCase())) {
      this.handleImgPreview(currentIndex, row, fileList);
      return false;
    }
    //  若当前点击项是可以使用office在线预览的
    if ([...officeFileType, "pdf"].includes(type.toLowerCase())) {
      this.getFileOnlineViewPathByOffice(row);
      return false;
    }
    //  若当前点击项是代码或文本文件
    let codeFileSuffix = type.toLowerCase();
    if (codeFileSuffix === "yaml") {
      codeFileSuffix = "yml";
    }
    // 无格式文件也可以在线编辑
    if (
      fileSuffixCodeModeMap.has(codeFileSuffix) ||
      (row.isDir === 0 && type === "")
    ) {
      Vue.prototype.$openBox.codePreview({ fileInfo: row, isEdit: false });
      return false;
    }
    //  若当前点击项是 markdown 文档
    if (markdownFileType.includes(type.toLowerCase())) {
      Vue.prototype.$openBox.markdownPreview({
        fileInfo: row,
        editable: false,
      });
      return false;
    }
    //  若当前点击项是视频mp4格式
    const VIDEO = ["mp4"];
    if (VIDEO.includes(type.toLowerCase())) {
      this.handleVideoPreview(currentIndex, row, fileList);
      return false;
    }
    //  若当前点击项是音频mp3格式
    const AUDIO = ["mp3"];
    if (AUDIO.includes(type.toLowerCase())) {
      Vue.prototype.$openBox.audioPreview({
        audioObj: row,
      });
      return false;
    }
  },
  /**
	 * 图片预览
	 * @param {*} currentIndex 当前图片索引
	 * @param {*} imgInfo 单个图片信息
	 * @param {*} imgInfoList 多个图片列表
	 */
	handleImgPreview(currentIndex, imgInfo = {}, imgInfoList = []) {
		// 图片分类下 - 传递整个页面的图片列表；非图片分类下 - 由单个图片构建图片列表
		const imgList =
			Number(router.currentRoute.query.fileType) === 1
				? imgInfoList.map((item) => {
						return {
							...item,
							fileUrl: this.getViewFilePath(item),
							downloadLink: this.getDownloadFilePath(item)
						}
				  })
				: [
						{
							...imgInfo,
							fileUrl: this.getViewFilePath(imgInfo),
							downloadLink: this.getDownloadFilePath(imgInfo)
						}
				  ]
		const defaultIndex =
			Number(router.currentRoute.query.fileType) === 1 ? currentIndex : 0
		Vue.prototype.$openBox.imgPreview({ imgList, defaultIndex })
	},
  /**
   * 获取文件下载路径
   * @param {object} row 文件信息
   * @returns {string}  文件下载路径
   */
  getDownloadFilePath(row) {
    return `${config.baseContext}/filetransfer/downloadfile?userFileId=${
      row.userFileId
    }&shareBatchNum=${
      row.shareBatchNum == null ? "" : row.shareBatchNum
    }&extractionCode=${row.extractionCode == null ? "" : row.extractionCode}`;
  },

  /**
   * 获取分享链接
   * @param {string} shareBatchNum
   * @returns {string} 完整的分享链接
   */
  getShareLink(shareBatchNum) {
    return `${location.protocol}//${location.host}/share/${shareBatchNum}`;
  },
  /**
   * 复制分享链接
   * @param {string} shareBatchNum
   * @param {string} extractionCode
   */
  copyShareLink(shareBatchNum, extractionCode) {
    let input = document.createElement("textarea"); // 直接构建textarea以保持换行
    input.value =
      extractionCode === null
        ? `分享链接：${this.getShareLink(
            shareBatchNum
          )}\n复制链接到浏览器中并输入提取码即可查看文件`
        : `分享链接：${this.getShareLink(
            shareBatchNum
          )}\n提取码：${extractionCode}\n复制链接到浏览器中并输入提取码即可查看文件`; // 设置内容
    document.body.appendChild(input); // 添加临时实例
    input.select(); // 选择实例内容
    document.execCommand("Copy"); // 执行复制
    document.body.removeChild(input); // 删除临时实例
    Message.success("复制成功");
  },

  /**
   * 根据文件扩展名设置文件图片
   * @param {object} file 文件信息
   */
  setFileImg(file) {
    let type = file.pathSuffix.split(".")[1];
    if (file.directory == true) {
      //  文件夹
      return fileImgMap.get("dir");
    }
    else if(type==undefined|| null){
      return unknownImg;
    } else if (fileImgMap.has(type.toLowerCase())) {
      // 可以识别文件类型的文件
      return fileImgMap.get(type.toLowerCase());
    } else {
      // 无法识别文件类型的文件
      return unknownImg;
    }
  },

  /**
   * 文件名称拼接，包括文件名称 + 文件后缀
   * @param {object} file 文件信息
   * @param {boolean} isHighlight 是否需要展示高亮效果，默认不需要
   * @returns {string} 完整文件名称
   */
  getFileNameComplete(file, isHighlight = false) {
    return isHighlight === true && file.highlightFields
      ? `${file.highlightFields}${
          file.isDir === 0 && file.extendName ? `.${file.extendName}` : ""
        }`
      : `${file.fileName}${
          file.isDir === 0 && file.extendName ? `.${file.extendName}` : ""
        }`;
  },
  /**
   * 文件类型
   * @param {object} file 文件信息
   */
  getFileType(file) {
    return file.isDir === 1
      ? "文件夹"
      : file.extendName
      ? file.extendName
      : "文件";
  },
  /**
   * 获取文件分享过期状态
   * @param {string} time 日期
   * @returns {boolean} 是否过期
   */
  getFileShareStatus(time) {
    if (new Date(time).getTime() > new Date().getTime()) {
      return false;
    } else {
      return true;
    }
  },
};

export default fileFunction;
