/**
 * 邮箱
 * @param {*} s
 */
export function isEmail (s) {
  return /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/.test(s)
}

/**
 * 手机号码
 * @param {*} s
 */
export function isMobile (s) {
  return /^1[0-9]{10}$/.test(s)
}

/**
 * 电话号码
 * @param {*} s
 */
export function isPhone (s) {
  return /^([0-9]{3,4}-)?[0-9]{7,8}$/.test(s)
}

/**
 * URL地址
 * @param {*} s
 */
export function isURL (s) {
  return /^http[s]?:\/\/.*/.test(s)
}
/**
 * 判断字符串是否以target结尾
 */
 export function confirmEnding(str, target) {
  var start = str.length-target.length;
  var arr = str.substr(start,target.length);
  if(arr == target){
       return true;
  }
return false;
}
/**
 * 判断输入是否是正整数
 */
export function isPositiveNum(s) {
    var re = /^[1-9]+\d*$/;
    return re.test(s);
}
/**
 * 判断输入是否最多保留两位小数
 */
export function isPositiveTwoDecimal(s) {
    console.log(s)
    var re = /^(([1-9]{1}\d*)|(0{1}))(\.\d{0,2})?$/;
    return re.test(s);
}
/**
 * 判断输入是否为两位小数
 */
export function isTwoDecimal(s) {
    var re = /^(\-|)(([1-9]{1}\d*)|(0{1}))(\.\d{0,2})?$/;
        // /^?\d+(\.\d{0,2})?$/;;
    return re.test(s);
}
/**
 *判断是否为特殊字符
 * */
export function isSpecialCharacter(s){
    var re = /[(\ )(\~)(\!)(\@)(\$)(\%)(\^)(\&)(\*)(\()(\))(\-)(\_)(\+)(\=)(\[)(\])(\{)(\})(\|)(\\)(\;)(\:)(\')(\")(\,)(\.)(\/)(\<)(\>)(\?)(\)]/

    return re.test(s);
}
