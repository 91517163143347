export default {
  namespaced: true,
  state: {
    // 文件展示模式 0 列表模式 | 1 网格模式 | 2 时间线模式
    fileModel: localStorage.getItem("minio_file_model"),
    // 网格模式 & 时间线模式下 图标大小 单位px
    gridSize: localStorage.getItem("minio_grid_size")
      ? Number(localStorage.getItem("minio_grid_size"))
      : 80,
  },
  mutations: {
    /**
     * 改变文件展示模式
     * @description 文件展示模式保存在 Vuex 和 cookie 中
     * @param {object} state Vuex 的 state 对象
     * @param {string} data 文件展示模式
     */
    changeFileModel(state, data) {
      localStorage.setItem("minio_file_model", data);
      state.fileModel = data;
    },
    /**
     * 网格模式 & 时间线模式 改变文件图标大小
     * @description 文件图标大小保存在 Vuex 和 cookie 中
     * @param {object} state Vuex 的 state 对象
     * @param {string} data 文件图标大小
     */
    changeGridSize(state, data) {
      localStorage.setItem("minio_grid_size", data);
      state.gridSize = data;
    },
  },
};
