<template>
  <div id="app">
    <router-view/>
    <!-- <router-view v-if="isReload"/> -->
  </div>
</template>
<script>
export default {
  name: 'App',
  data() {
    return {
   
    }
  },
  methods: {

  }
}
</script>

