import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueCookie from "vue-cookie";
import "@/icons";
import VueClipboard from "vue-clipboard2";
// import '@/element-ui-theme'
import "@/assets/scss/index.scss";

import httpRequest from "./utils/httpRequest"; // api: https://github.com/axios/axios
import { isAuth } from "@/utils";
import cloneDeep from "lodash/cloneDeep";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
// import VueAxios from 'vue-axios'
import axios from "axios";
import "./assets/ifont/iconfont.css";
import "./assets/directives";
// 引入minio全局函数
import globalFunction from '../src/libs/globalFunction/index.js'
for (let key in globalFunction) {
	Vue.prototype[`$${key}`] = globalFunction[key]
}

import "./assets/css/global.css";
import "./assets/css/index.css";
import "lib-flexible";
import InnerObject from "../static/config/test";
import jm from "vue-jsmind";
Vue.config.InnerObject = InnerObject;
import {
  postFilesRequest,
  postRequest,
  putRequest,
  deleteRequest,
  getRequest,
  postKeyValueRequest,
} from "./utils/api";
import { treeDataTranslate } from "./utils/treeDataTranslate";
Vue.use(jm);
Vue.use(VueClipboard);
if (window.jsMind) {
  console.log("wind");
  Vue.prototype.jsMind = window.jsMind;
}
Vue.prototype.postRequest = postRequest;
Vue.prototype.postKeyValueRequest = postKeyValueRequest;
Vue.prototype.putRequest = putRequest;
Vue.prototype.deleteRequest = deleteRequest;
Vue.prototype.getRequest = getRequest;
Vue.prototype.postFilesRequest = postFilesRequest;
Vue.prototype.treeDataTranslate = treeDataTranslate;



import { initMenu } from "./utils/menus";

Vue.prototype.$echarts = window.echarts;
Vue.config.productionTip = false;
// Vue.use(axios)
Vue.use(VueCookie);
// Vue.use(VueAxios,axios)
Vue.use(ElementUI, { size: "small" });

// 保存整站vuex本地储存初始状态
window.SITE_CONFIG["storeState"] = cloneDeep(store.state);
console.log(window.SITE_CONFIG);
// window.SITE_CONFIG['storeState'] = 1
Vue.prototype.$http = httpRequest; // ajax请求方法
Vue.prototype.isAuth = isAuth; // 权限方法

// router.beforeEach((to,from,next) => {  //前置守卫，调用menus里的initMenu方法，会从后端获取菜单列表，填充到vuex的store中，从Home的compete属性获取store中的routes，将原来的路由替换掉即可
//   if (to.path == '/'){
//     next();
//   }else {
//     //initMenu(router,store);    //先把它注释掉，后台没写这个接口
//     next();
//   }
// })

import global from "./utils/global";
Vue.prototype.global = global;

Vue.prototype.$changeGlobalVar = function (mode, value1, value2) {
  if (mode === this.global.MODE_COAL) {
    this.global.coal_id = value1;
    this.global.coal_name = value2;
  }
  if (mode === this.global.MODE_SYSTEM) {
    this.global.system_id = value1;
    this.global.system_name = value2;
  }
  if (mode === this.global.MODE_SUB_SYSTEM) {
    this.global.subSystem_id = value1;
    this.global.subSystem_name = value2;
  }
  if (mode === this.global.MODE_EQUIPMENT) {
    this.global.equipment_id = value1;
    this.global.equipment_name = value2;
  }
  if (mode === this.global.MODE_SUB_EQUIPMENT) {
    this.global.sub_equipment_id = value1;
    this.global.sub_equipment_name = value2;
  }
  if (mode === this.global.MODE_MEASURE_POINT) {
    this.global.measure_point_id = value1;
    this.global.measure_point_name = value2;
  }
};
Vue.prototype.$resetGlobalVar = function () {
  this.global.coal_name = "";
  this.global.coal_id = 0;
  this.global.system_name = "";
  this.global.system_id = 0;
  this.global.subSystem_name = "";
  this.global.subSystem_id = 0;
  this.global.equipment_name = "";
  this.global.equipment_id = 0;
  this.global.sub_equipment_name = "";
  this.global.sub_equipment_id = 0;
  this.global.measure_point_name = "";
  this.global.measure_point_id = 0;
};
Vue.prototype.$setProcess = function (subEquipId, process) {
  // let obj = {
  //   // id: subEquipId,
  //   process: process
  // }
  // this.global.processList.forEach(item => {
  //   if (item.subEquipId == subEquipId) {
  //     this.global.processList.assign(item, obj)
  //     return
  //   }
  // })
  if (subEquipId in this.global.processMap.keys())
    this.global.processMap[subEquipId] = process;
  else this.global.processMap.set(subEquipId, process);

  console.log(this.global.processMap);
};
Vue.prototype.$getProcess = function (subEquipId) {
  if (this.global.processList === []) {
    return 0;
  } else {
    this.global.processList.forEach((item) => {
      if (item.subEquipId === subEquipId) {
        console.log("here1", item);
        return item.process;
      }
    });
    return 0;
  }
};

new Vue({
  router,
  store,
  render: (h) => h(App),
  beforeCreate() {
    Vue.prototype.$bus = this; //安装全局事件总线
  },
}).$mount("#app");
